import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {HashRouter as Router} from 'react-router-dom';
import configureStore from '../configureStore';
import {Auth0Provider} from '@auth0/auth0-react';
import _ from "lodash";
import AppLayout from "./AppLayout";
const App = (props) => {
    const clientId =  _.get(window, 'clientConfig.auth0ClientId');
    const domainUrl = _.get(window, 'clientConfig.domain');
    return (
        <Provider store={configureStore(props.initialState)}>
                <Auth0Provider
                    domain={domainUrl}
                    clientId={clientId}
                    cacheLocation="localstorage"
                    authorizationParams={{
                        scope: 'profile email offline-access',
                        audience: 'longtail-publisher' ,
                        redirect_uri: `${window.location.href}`
                    }}
                >
                    <Router><AppLayout /></Router>
                </Auth0Provider>
        </Provider>
    );
};

App.propTypes = {
    initialState: PropTypes.object
};
export default App;
