import _ from 'lodash';
import {enums} from '@perion-undertone/ramp-helpers';
import {loadFromLocalStorage, saveToLocalStorage} from './utils';
import {isTimestampExpired} from './utils/dateUtils';
import {redirectToLoginPage} from './helpers/historyHelper';
import {setTokenCookies, clearCookies, getCookie} from './helpers/cookieHelper';
const {PUBLISHER_ROLE_TYPES} = enums
const {UNDERTONE_ADMIN, PUBLISHER_USER, PUBLISHER_ADMIN, PUBLISHER_DSP} = PUBLISHER_ROLE_TYPES;
export const UNDERTONE_NAMESPACE = 'https://undertone.com/';
export const USER_TYPES = {
    COGNITO: 1,
    WORKFLOW: 2
};

const urlParams = new URLSearchParams(window.location.href.split('?')[1]);

export const isAdmin = (user) => {
    return user && (user.userType === USER_TYPES.WORKFLOW || parseInt(user.role) === UNDERTONE_ADMIN);
};

export const isPublisherDSP = (user) => {
    return user && parseInt(user.role) === PUBLISHER_DSP;
};

export const isPublisherUserOrPublisherAdmin = (user) => {
    return user && (
        parseInt(user.role) === PUBLISHER_USER ||
        parseInt(user.role) === PUBLISHER_ADMIN ||
        parseInt(user.role) === PUBLISHER_DSP
    );
};
// export const setRole = (user) => {
//     const roles = _.get(user, 'https://undertone.com/roles', []);
//     const rolesAsConst = _.map(roles, (role) => _.upperCase(_.snakeCase(role)));
//     const uDriveRoles = _.pick(PUBLISHER_ROLE_TYPES, rolesAsConst);
//     return _.assign({}, user, {role: _.first(_.values(uDriveRoles))});
// }
export const setUserPublisherId = (user) => {
    const appMetadata = _.get(user, 'app_metadata', {});
    const allowedPublisherId = appMetadata['allowedPublisherId'];
    const currentPublisherId = appMetadata['currentPublisherId'];
    return _.assign({}, user, {allowedPublisherId, currentPublisherId});
}
// export const setUserType = (user) => {
//     const userType = _.has(user, 'user_mata_data.wf_id') ? USER_TYPES.WORKFLOW : USER_TYPES.COGNITO
//     return _.assign({}, user, {userType});
// }
export const getSurveyData = () => {
    return loadFromLocalStorage('surveys');
};

export const getCurrentUser = async () => {
    const token = urlParams.get('token');

    if (_.startsWith(window.location.hash, 'activation?id=', 2)) {
        const verifyAccountId = urlParams.get('id');
        return getUserFromSession(verifyAccountId);
    }

    let user = loadFromLocalStorage("user");

    const session = await refreshSession();
    if (session) {
        user = session.user;
    }


    if (token) {
        if (user && user.userType === USER_TYPES.COGNITO) {
            userLogout();
        }
        user = await getWorkflowUser(token);
        if (user) {
            saveToLocalStorage('user', user);
        }
    }
    return Promise.resolve(user);
};

export const getWorkflowUser = async (token) => {
    const response = await fetch(`/user?token=${token}`);
    const data = await response.json();

    if (data.status === 401) return;
    return {
        userType: USER_TYPES.WORKFLOW,
        ...data
    };
};

const getUserFromSession = async (verifyAccountId) => {
    const verifyData = await fetch(`/api/auth/verify?id=${verifyAccountId}`);
    const data = await verifyData.json();

    saveToLocalStorage('user', data.session.user);
    setTokenCookies(data.session.tokens);
    return Promise.resolve(data.session.user);
};

export const refreshSession = async () => {
    const user = loadFromLocalStorage('user');
    const token = getCookie('cg-refresh-token');
    const tokenExp = getCookie('cg-id-token-exp');

    if (!user || user.userType !== USER_TYPES.COGNITO) {
        return;
    }

    const {username, keepMeSignedIn} = user;
    if (keepMeSignedIn === '0' || !isTimestampExpired(tokenExp)) {
        return;
    }

    const response = await fetch(`/api/auth/refresh-session?username=${username}&refreshToken=${token}`);
    const data = await response.json();

    if (!data || data.err) {
        userLogout();
        redirectToLoginPage();
    }

    saveToLocalStorage('user', data.user);
    setTokenCookies(data.tokens);

    return data;
};

export const userLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('surveys');
    clearCookies();
};
