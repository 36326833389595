import _ from 'lodash';
import AdUnitsFormatter from './AdUnitsFormatter';
import IdColumnFormatter from './IdColumnFormatter';
import PlacementTagTypeColumnFormatter from './PlacementTagTypeColumnFormatter';
import StatusFormatter from './StatusFormatter';
import IconsFormatter from './IconsFormatter'
import ActionsFormatter from './ActionsFormatter';
import DescriptionFormatter from './DescriptionFormatter';
import NameFormatter from './NameFormatter';
import textProvider from '../../../../texts/textProvider';
import headerCellRenderer from '../../../headerCellRenderer';
import columnSearchTextFilter from '../../../../components/grid/filters/ColumnSearchTextFilter';
import columnCheckboxFilter from '../../../../components/grid/filters/ColumnCheckboxFilter';
import LiDescriptionFormatter from './LIDescriptionFormatter';


const CLASS_NAMES = {
    NAME_CELL: 'name-cell',
    DESCRIPTION: 'descriptions-cell',
    ADUNITS_CELL: 'ad-units-cell',
    LAST_MODIFIED_CELL: 'last-modified-cell',
    DELIVERY_TYPE_CELL: 'delivery-type-cell',
    ID_CELL: 'id-cell',
    STATUS_CELL: 'status-cell',
    ICONS_CELL: 'icons-cell',
    ACTIONS_CELL: 'actions-cell',
    LI_DESCRIPTION_CELL: 'li-description-cell',
}

const CONSTANT_TEXTS = {
    NAME: textProvider.getText('placementGroupGrid', 'name'),
    DESCRIPTION: textProvider.getText('placementGroupGrid', 'description'),
    ADUNITS: textProvider.getText('placementGroupGrid', 'adUnits'),
    LAST_MODIFIED: textProvider.getText('placementGroupGrid', 'lastModified'),
    DELIVERY_TYPE: textProvider.getText('placementGroupGrid', 'deliveryType'),
    ID: textProvider.getText('placementGroupGrid', 'id'),
    TAG_TYPE: textProvider.getText('placementGroupGrid', 'tagType'),
    STATUS: textProvider.getText('placementGroupGrid', 'status'),
    LI_DESCRIPTION: textProvider.getText('placementGroupGrid', 'liDescription'),
};

export const placementGroupGridSchema = (viewDescription, editOrDeletePlacement, publisherTypeCd) => {
    const cols = [
        {
            name: CONSTANT_TEXTS.NAME,
            key: "name",
            width: window.screen.width >= 1600 ? 320 : 270,
            formatter: NameFormatter,
            cellClass: CLASS_NAMES.NAME_CELL,
            headerRenderer: headerCellRenderer,
            isSortable: true,
            filterComponent: columnSearchTextFilter
        },
        viewDescription && {
            name: CONSTANT_TEXTS.DESCRIPTION,
            key: "description",
            id: "description",
            cellClass: CLASS_NAMES.DESCRIPTION,
            width: window.screen.width >= 1600 ? 320 : 220,
            formatter: DescriptionFormatter,
            headerRenderer: headerCellRenderer,
            isSortable: false
        },
        {
            name: CONSTANT_TEXTS.ADUNITS,
            key: "adUnits",
            id: "adUnits",
            cellClass: CLASS_NAMES.ADUNITS_CELL,
            width: window.screen.width >= 1600 ? 320 : 220,
            formatter: AdUnitsFormatter,
            headerRenderer: headerCellRenderer,
            isSortable: false,
            // filterComponent: columnCheckboxFilter,
            // isSearchFilter: true
        },
        {
            name: CONSTANT_TEXTS.LAST_MODIFIED,
            key: "updatedAt",
            cellClass: CLASS_NAMES.LAST_MODIFIED_CELL,
            width: window.screen.width >= 1600 ? 150 : 100,
            formatter: ({value}) => {
                return value ? (new Date(value)).toLocaleDateString().replace(/\//g, '.') : '';
            },
            headerRenderer: headerCellRenderer,
            isSortable: true
        },
        {
            name: CONSTANT_TEXTS.ID,
            key: "id",
            id: "placementGroupId",
            width: window.screen.width >= 1600 ? 250 : 160,
            cellClass: CLASS_NAMES.ID_CELL,
            formatter: IdColumnFormatter,
            headerRenderer: headerCellRenderer,
            isSortable: true,
            filterComponent: columnSearchTextFilter
        },
        {
            name: CONSTANT_TEXTS.TAG_TYPE,
            key: "placementTagTypeName",
            id: "placementTagTypeName",
            cellClass: CLASS_NAMES.STATUS_CELL,
            width: window.screen.width >= 1600 ? 180 : 170,
            formatter: PlacementTagTypeColumnFormatter,
            headerRenderer: headerCellRenderer,
            isSortable: true,
            filterComponent: columnSearchTextFilter
        },
        {
            name: CONSTANT_TEXTS.STATUS,
            key: "status",
            id: "status",
            cellClass: CLASS_NAMES.STATUS_CELL,
            width: window.screen.width >= 1600 ? 140 : 100,
            formatter: StatusFormatter,
            headerRenderer: headerCellRenderer,
            isSortable: true,
            filterComponent: columnCheckboxFilter
        },
        publisherTypeCd === "DSP" && {
            name: CONSTANT_TEXTS.LI_DESCRIPTION,
            key: "liDescription",
            id: "liDescription",
            cellClass: CLASS_NAMES.LI_DESCRIPTION_CELL,
            width: window.screen.width >= 1600 ? 140 : 100,
            formatter: LiDescriptionFormatter,
            headerRenderer: headerCellRenderer,
            isSortable: false,
        },
        {
            name: '',
            key: 'icons',
            id: 'icons',
            cellClass: CLASS_NAMES.ICONS_CELL,
            formatter: IconsFormatter,
            width: window.screen.width >= 1600 ? 140 : 100
        },
        editOrDeletePlacement && {
            name: '',
            key: "actions",
            cellClass: CLASS_NAMES.ACTIONS_CELL,
            formatter: ActionsFormatter,
            width: window.screen.width >= 1600 ? 150 : 140,
        }
    ].filter(col => !_.isEmpty(col))
    return cols;
};
