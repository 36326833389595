import {isUndefined, get} from 'lodash';
import {appConstants} from '../constants/appConstants';
import {accountConstants} from '../constants/accountConstants';
import {appStates} from '../constants/appStates';
import {userLogout} from '../auth';
import {redirectToHomePage, redirectToLoginPage} from '../helpers/historyHelper';
//import {setTokenCookies} from '../helpers/cookieHelper';
import {saveToLocalStorage} from "../utils";
import {onboardingConstants} from '../constants/onboardingConstants';
import _ from 'lodash';

const initialState = {
    user: {
        data: null,
        state: appStates.DEFAULT,
        qrCode: null,
        mfaEnabled: false
    }
};

export const appReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case appConstants.LOGIN_REQUEST: {
            return {
                ...state,
                user: {
                    ...state.user,
                    data: undefined,
                    state: appStates.LOADING,
                    loginError: undefined
                }
            };
        }
        case appConstants.MFA_CHALLENGE: {
            const {code, token} = payload;
            saveToLocalStorage('mfaEnabled', true);
            return {
                ...state,
                user: {
                    data: null,
                    state: appStates.LOADING,
                    loginError: undefined,
                    mfaEnabled : true,
                    ...(!_.isNil(code) && !_.isEmpty(code) && {qrCode: code}),
                    token
                }
            };
        }
        case appConstants.LOGIN_SUCCESS: {
            const {user, accessToken} = payload.profileData;
            saveToLocalStorage('user', user);
            redirectToHomePage();
            window.UdriveAppUser = user;
            return {
                ...state,
                user: {
                    accessToken,
                    data: user,
                    state: appStates.LOADED,
                    mfaEnabled: true,
                    loginError: false
                },
                showResetPassword: undefined,
                resetRequest: undefined,
                setPassRequest: undefined,
                createPassRequest: undefined
            }
        }
        case appConstants.LOGIN_FAILURE: {
            const {message} = get(payload, 'error.response');
            return {
                ...state,
                user: {
                    ...state.user,
                    data: undefined,
                    state: appStates.ERROR,
                    loginError: message
                }
            };
        }
        case appConstants.GET_USER_INFO_REQUEST: {
            return {
                ...state,
                user: {
                    ...state.user,
                    data: undefined,
                    state: appStates.LOADING,
                    loginError: undefined
                }
            };
        }
        case appConstants.GET_USER_INFO_SUCCESS: {
            const user = payload.user;
            const token = payload.token;
            saveToLocalStorage('user', user);
            redirectToHomePage();
            window.UdriveAppUser = user;
            return {
                ...state,
                user: {
                    accessToken: token,
                    data: user,
                    state: appStates.LOADED,
                    mfaEnabled: true,
                    loginError: false
                },
                showResetPassword: undefined,
                resetRequest: undefined,
                setPassRequest: undefined,
                createPassRequest: undefined
            }
        }
        case appConstants.GET_USER_INFO_FAILURE: {
            const {message} = get(payload, 'error.response');
            return {
                ...state,
                user: {
                    ...state.user,
                    data: undefined,
                    state: appStates.ERROR,
                    loginError: message
                }
            };
        }
        case appConstants.LOGOUT_USER: {
            userLogout();
            redirectToLoginPage();
            return {
                user: {
                    state: appStates.DEFAULT
                }
            }
        }
        case appConstants.LOGOUT_FAILURE: {
            return {
                ...state,
                user: {
                    data: undefined,
                    state: appStates.ERROR
                }
            }
        }

        case appConstants.AUTHORIZATION_DATA_REQUEST: {
            return {
                ...state,
                auth: {
                    data: undefined,
                    state: appStates.LOADING
                }
            }
        }
        case appConstants.AUTHORIZATION_DATA_SUCCESS: {
            return {
                ...state,
                auth: {
                    data: payload.authorizationData,
                    state: appStates.LOADED
                }
            }
        }
        case appConstants.AUTHORIZATION_DATA_FAILURE: {
            return {
                ...state,
                auth: {
                    data: undefined,
                    state: appStates.ERROR
                }
            }
        }

        case appConstants.CURRENT_PUBLISHER_REQUEST: {
            if (payload.withUserUpdate) {
                return {
                    ...state,
                    publisher: {
                        data: undefined,
                        state: appStates.LOADING
                    },
                    userUpdateState: appStates.LOADING
                }
            }
            return {
                ...state,
                publisher: {
                    data: undefined,
                    state: appStates.LOADING
                }
            }
        }
        case appConstants.CURRENT_PUBLISHER_SUCCESS: {
            if (payload.user) {
                const profileData = payload.user;
                saveToLocalStorage('user', profileData);
                //setTokenCookies(profileData.tokens);
                redirectToHomePage();
                location.reload();

                return {
                    ...state,
                    user: {
                        ...state.user,
                        data: payload.user.user,
                    },
                    publisher: {
                        data: payload.publisher,
                        state: appStates.LOADED
                    },
                    userUpdateState: appStates.LOADED
                }
            }
            return {
                ...state,
                publisher: {
                    data: payload.publisher,
                    state: appStates.LOADED
                }
            }
        }
        case appConstants.CURRENT_PUBLISHER_FAILURE:
            return {
                ...state,
                publisher: {
                    data: undefined,
                    state: appStates.ERROR
                }
        };

        case appConstants.FETCH_PUBLISHERS_REQUEST: {
            return {
                ...state,
                publishers: {
                    data: undefined,
                    state: appStates.LOADING
                }
            }
        }
        case appConstants.FETCH_PUBLISHERS_SUCCESS: {
            return {
                ...state,
                publishers: {
                    data: payload.publishers,
                    state: appStates.LOADED
                }
            }
        }
        case appConstants.FETCH_PUBLISHERS_FAILURE:
            return {
                ...state,
                publishers: {
                    data: undefined,
                    state: appStates.ERROR
                }
        };

        case appConstants.RESET_PASSWORD_REQUEST: {
            if (payload.isResend) {
                return {
                    ...state,
                    resendRequest: {
                        state: appStates.LOADING,
                        isEmailSent: false
                    }
                };
            }
            return {
                ...state,
                resetRequest: {
                    state: appStates.LOADING,
                    isEmailSent: false
                }
            };
        }
        case appConstants.RESET_PASSWORD_SUCCESS: {
            if (payload.isResend) {
                return {
                    ...state,
                    resendRequest: {
                        state: appStates.LOADED,
                        isEmailSent: true
                    },
                };
            }
            return {
                ...state,
                resetRequest: {
                    state: appStates.LOADED,
                    isEmailSent: true
                },
            };
        }
        case appConstants.RESET_PASSWORD_FAILURE: {
            return {
                ...state,
                resetRequest: {
                    state: appStates.ERROR,
                    isEmailSent: false,
                    errorMessage: payload.error.message
                }
            };
        }

        case onboardingConstants.SET_PREFILLED_ACCOUNT_DATA: {
            return {
                ...state,
                prefillAccount: payload
            };
        }

        case accountConstants.CREATE_ACCOUNT_REQUEST: {
            return {
                ...state,
                account: {
                    username: payload.data.username,
                    password: payload.data.password,
                    state: appStates.LOADING,
                    data: undefined
                }
            };
        }
        case accountConstants.CREATE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                account: {
                    ...state.account,
                    state: appStates.LOADED,
                    data: payload.data
                }
            };
        }
        case accountConstants.CREATE_ACCOUNT_FAILURE: {
            return {
                ...state,
                account: {
                    ...state.account,
                    state: appStates.ERROR,
                    error: payload.error
                }
            };
        }

        case onboardingConstants.GET_ONBOARDING_DATA_REQUEST: {
            return {
                ...state,
                onboarding: {
                    state: appStates.LOADING,
                    mainSite: undefined
                }
            };
        }
        case onboardingConstants.GET_ONBOARDING_DATA_SUCCESS: {
            saveToLocalStorage('user', payload.userData);
            return {
                ...state,
                user: {
                    ...state.user,
                    data: payload.userData
                },
                onboarding: {
                    state: appStates.LOADED,
                    mainSite: payload.mainSite
                }
            };
        }
        case onboardingConstants.GET_ONBOARDING_DATA_FAILURE: {
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    state: appStates.ERROR,
                    error: payload.error
                }
            };
        }

        case onboardingConstants.DISPLAY_ENTER_SITE: {
            const {shouldDisplay} = payload;
            const shouldDisplayEnterSite = isUndefined(shouldDisplay) ? true : shouldDisplay;
            return {
                ...state,
                shouldDisplayEnterSite
            };
        }

        case onboardingConstants.ADD_MAIN_SITE_REQUEST:
        case onboardingConstants.VALIDATE_MAIN_SITE_REQUEST: {
            if (payload.isValidating) {
                return {
                    ...state,
                    onboarding: {
                        ...state.onboarding,
                        isValidating: payload.isValidating
                    }
                };
            }
            return {
                ...state,
                shouldDisplayEnterSite: false,
                onboarding: {
                    state: appStates.LOADING,
                    mainSite: undefined
                }
            };
        }
        case onboardingConstants.ADD_MAIN_SITE_SUCCESS:
        case onboardingConstants.VALIDATE_MAIN_SITE_SUCCESS: {
            saveToLocalStorage('user', payload.userData.user);
            return {
                ...state,
                user: {
                    ...state.user,
                    data: payload.userData.user
                },
                onboarding: {
                    state: appStates.LOADED,
                    mainSite: payload.mainSite,
                    isValidating: false
                }
            };
        }
        case onboardingConstants.ADD_MAIN_SITE_FAILURE:
        case onboardingConstants.VALIDATE_MAIN_SITE_FAILURE: {
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    state: appStates.ERROR,
                    error: payload.error,
                    isValidating: false
                }
            };
        }

        case appConstants.SET_PASSWORD_REQUEST: {
            return {
                ...state,
                setPassRequest: {
                    state: appStates.LOADING,
                    id: payload.id,
                    password: payload.password
                }
            };
        }
        case appConstants.SET_PASSWORD_SUCCESS: {
            return {
                ...state,
                setPassRequest: {
                    ...state.setPassRequest,
                    state: appStates.LOADED,
                    isSet: true
                }
            };
        }
        case appConstants.SET_PASSWORD_ERROR: {
            return {
                ...state,
                setPassRequest: {
                    state: appStates.ERROR,
                    errorMessage: payload.error.message
                }
            };
        }

        case appConstants.SET_FLAG_SITE_UNDER_REVIEW_PAGE: {
            const {hideSiteUnderReviewPage} = payload;
            return {
                ...state,
                hideSiteUnderReviewPage
            };
        }

        case appConstants.RESET_APP_STATE: {
            if (payload.data) {
                return {
                    ...payload.data,
                    ...initialState
                };
            }
            return initialState;
        }
        default:
            return state;
    }
};
