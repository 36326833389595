import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Checkbox, Tooltip, Icon} from '@perion-undertone/ut-react-common';

import {
    Image,
    textProvider,
    toggleSelectAdUnit,
    toggleSelectAllAdUnits,
    previewAdUnitInfo
} from '../imports';
import {selectFeatureFlag} from "@flopflip/react-redux";
import _ from "lodash";

const CLASS_NAMES = {
    AD_UNIT_GROUP: 'ad-unit-group',
    AD_UNIT_TYPE_NAME: 'ad-unit-type-name',
    AD_UNIT_TYPE_DESCRIPTION: 'ad-unit-type-description',
    AD_UNIT_IMG: 'ad-unit-img',
    AD_UNIT_GROUP_CB_FEW_SELECTED: 'ad-unit-group-cb-few-selected',
    CPM_VALUE: 'cpm-value',
    AD_UNITS_LIST: 'ad-units-list',
    HEADER_TAG: 'header-tag',
    SELECT_BOX: 'selectBox',
    SELECT_GROUP_ALL_BOX: 'select-group-all-box',
    GROUP_NAME: 'group-name',
    SELECTED: 'selected',
    NAME_COUNTER_CONTAINER: 'name-counter-container',
    PREVIEW_CLICKED_AD_UNIT: 'preview-clicked-ad-unit',
    AD_UNIT_DESCRIPTION: 'ad-unit-description',
    CHECKBOX_AND_NAME_CONTAINER:'checkbox-and-name-container',
    CONTAINER_MISSING_ICON: 'container-hoverable-missing-adunit-settings-icon',
    MISSING_ICON: 'hoverable-missing-adunit-settings-icon',
    MISSING_ICON_TOOLTIP: 'missing-icon-tooltip',
}

const CONSTANT_TEXTS = {
    CPM: textProvider.getText('adUnits', 'CPM'),
    DESCRIPTION: textProvider.getText('common', 'description'),
    ADUNIT_NAME: textProvider.getText('common', 'adUnitName'),
    MISSING_HB: textProvider.getText('adUnits', 'missingHb'),
    MISSING_RATE_CARDS: textProvider.getText('adUnits', 'missingRateCards'),
    MISSING_RATE_CARDS_AND_HB: textProvider.getText('adUnits', 'missingHbAndRateCards'),
}

class AdUnitGroup extends Component {

    constructor(props) {
        super(props);

        this.selectAdUnit = this.selectAdUnit.bind(this);
        this.selectAllAdUnits = this.selectAllAdUnits.bind(this);
        this.clickPreviewAdUnitInfo = this.clickPreviewAdUnitInfo.bind(this);
    }

    selectAdUnit(adUnit) {
        this.props.toggleSelectAdUnit(adUnit);
    }

    selectAllAdUnits(isSelectAllChecked, allGroupAdunits, adUnitKey) {
        this.props.toggleSelectAllAdUnits(allGroupAdunits, isSelectAllChecked, adUnitKey);
    }

    clickPreviewAdUnitInfo(adUnit) {
        this.props.previewAdUnitInfo(adUnit);
    }

    getTooltipTriggerIcon(adUnit) {
        const {isPlacementGroupTagTypesEnabled} = this.props;
        const adUnitIcon = isPlacementGroupTagTypesEnabled && (!adUnit.hasActiveRateCardLine || !adUnit.hasHbSettings) ?
            <Icon icon='icon-additional_details' /> : null;
        return (
            <div className={CLASS_NAMES.CONTAINER_MISSING_ICON}>
              <div className={CLASS_NAMES.MISSING_ICON}>
                {adUnitIcon}
              </div>
            </div>
        )
    }

    getRow(adUnit) {
        const {previewAdUnit, isPlacementGroupTagTypesEnabled} = this.props;
        const adUnitClasses = cx(CLASS_NAMES.AD_UNITS_LIST, {
            [CLASS_NAMES.SELECTED]: adUnit.isSelected,
            [CLASS_NAMES.PREVIEW_CLICKED_AD_UNIT]: previewAdUnit && adUnit.id === previewAdUnit.id
        });
        const {hasActiveRateCardLine, hasHbSettings} = adUnit;

        return (
            <div className={adUnitClasses}
                 onClick={() => this.clickPreviewAdUnitInfo(adUnit)}
            >
                <div className={CLASS_NAMES.CHECKBOX_AND_NAME_CONTAINER}>
                    <Checkbox
                        squared
                        className={CLASS_NAMES.SELECT_BOX}
                        checked={adUnit.isSelected}
                        onChange={() => this.selectAdUnit(adUnit)}
                        hookId='select-ad-unit-checkbox'
                    />
                    <div className={CLASS_NAMES.HEADER_TAG}>
                        <div>{adUnit.name}</div>
                    </div>
                </div>
                    { isPlacementGroupTagTypesEnabled && (!hasActiveRateCardLine || !hasHbSettings) ?
                        <Tooltip
                            placement='top'
                            className={CLASS_NAMES.MISSING_ICON_TOOLTIP}
                            trigger={this.getTooltipTriggerIcon(adUnit)}
                            hookId='ad-unit-group-tooltip'
                            appendToBody={true}
                        >
                                {!hasActiveRateCardLine && !hasHbSettings && CONSTANT_TEXTS.MISSING_RATE_CARDS_AND_HB}
                                {!hasActiveRateCardLine && hasHbSettings && CONSTANT_TEXTS.MISSING_RATE_CARDS}
                                {hasActiveRateCardLine && !hasHbSettings && CONSTANT_TEXTS.MISSING_HB}
                        </Tooltip> : this.getTooltipTriggerIcon(adUnit)}
            </div>
        )
    }

    render() {
        const {adUnits, adUnitKey, currentGroup} = this.props;
        const selectedAdUnits = _.get(currentGroup , 'adUnits', []).map(adUnit => adUnit.id);
        const activeAdUnits = adUnits[adUnitKey].map(adUnit => ({
            ...adUnit,
            isSelected: selectedAdUnits.includes(adUnit.id)
        }))

        if (activeAdUnits.length < 1) {
            return <div></div>;
        }

        const isSelectAllChecked = adUnits[adUnitKey].every(adUnit => selectedAdUnits.includes(adUnit.id));
        const allGroupAdunits = adUnits[adUnitKey].map(adUnit => adUnit);
        const isFewSelected = !isSelectAllChecked && adUnits[adUnitKey].some(adUnit => selectedAdUnits.includes(adUnit.id));

        return (
            <div className={CLASS_NAMES.AD_UNIT_GROUP}>
                <div className={CLASS_NAMES.NAME_COUNTER_CONTAINER}>
                    <div className={CLASS_NAMES.GROUP_NAME}>
                        <div className={CLASS_NAMES.AD_UNIT_TYPE_NAME}>
                            {activeAdUnits.length > 0 ? adUnitKey : ''}
                        </div>
                        <Checkbox squared
                                  label={CONSTANT_TEXTS.ADUNIT_NAME}
                                  className={cx(CLASS_NAMES.SELECT_GROUP_ALL_BOX,
                                    {[CLASS_NAMES.AD_UNIT_GROUP_CB_FEW_SELECTED]: isFewSelected})
                                  }
                                  checked={isSelectAllChecked}
                                  onChange={() => this.selectAllAdUnits(isSelectAllChecked, allGroupAdunits, adUnitKey)}
                                  hookId='select-ad-unit-checkbox'
                        />
                    </div>
                    <div className={CLASS_NAMES.AD_UNIT_IMG}>
                        <Image name={`adunit-${adUnitKey.toLowerCase()}.png`}
                               hookId={`adunit-${adUnitKey.toLowerCase()}-img`}
                        />
                    </div>
                </div>
                {activeAdUnits.map((adUnit, i) => {
                    return (
                        <div key={i}>
                            {this.getRow(adUnit)}
                        </div>
                    )
                })}
            </div>
        );
    }
}

AdUnitGroup.propTypes = {
    isSelected: PropTypes.bool,
    adUnits: PropTypes.object,
    adUnitKey: PropTypes.string,
    toggleSelectAdUnit: PropTypes.func,
    toggleSelectAllAdUnits: PropTypes.func,
    previewAdUnitInfo: PropTypes.func,
    currentGroup: PropTypes.object,
    previewAdUnit: PropTypes.object,
    isPlacementGroupTagTypesEnabled: PropTypes.bool
};

const mapStateToProps = (state) => ({
    isPlacementGroupTagTypesEnabled: selectFeatureFlag('placementGroupTagTypes')(state),
    currentGroup: state.placements.currentGroup,
    previewAdUnit: state.placements.currentGroup ? state.placements.currentGroup.previewAdUnit : null,
    adUnits: state.placements.adUnits.data
})

const mapDispatchToProps = ({
    toggleSelectAdUnit,
    toggleSelectAllAdUnits,
    previewAdUnitInfo
});

export default connect(mapStateToProps, mapDispatchToProps)(AdUnitGroup);
