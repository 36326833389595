import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import './fontPreLoader';

import 'normalize.css';
import 'react-virtualized/styles.css';
import '@perion-undertone/ut-ui-resources/utIcon/ut-icon.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '../stylesheets/main.scss';

const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
const inspectUpdates = urlParams.get('inspectUpdates');

if (process.env.NODE_ENV !== 'production' && inspectUpdates) {
    const {whyDidYouUpdate} = require('why-did-you-update');
    whyDidYouUpdate(React);
}

const prerenderer = async () => {
    const initialState = {};
    try{
        const res = await fetch(`/config`);
        const config = await res.json();
        window.clientConfig = config;
    } catch (err) {
        initialState.app = {error: 'App could not load'};
    }

    ReactDOM.render(
        <App initialState={initialState} />, document.getElementById('app'));
};

prerenderer();
