import React, {Fragment} from 'react';
import {Route} from 'react-router-dom';
import Home from './Home';

const AppRouter = () => {
    return (
        <Fragment>
                <Route path="/:publisherId?" component={Home}/>
        </Fragment>
    )
};

export default AppRouter;
