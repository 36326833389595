import {loadFromLocalStorage, saveToLocalStorage} from '../utils';
import {publisherTypesInit, rampFetch} from '../utils/globals';
import metadataProvider from '../utils/MetadataProvider';
import {GAProvider} from '@perion-undertone/ut-react-common';
import _ from 'lodash';


const VERSION_STORAGE_KEY = 'metadataVersion';
const METADATA_STORAGE_KEY = 'metadata';
const METADATA_ITEMS = ['PlacementWinEventTypes', 'SupplyStores', 'PlacementPriceMacroType', 'Publisher_target'];

export const preRender = async({user}) => {
    const isDev = false;
    const config = window.clientConfig;
    GAProvider.initialize(config.googleAnalyticsSuiteId, {
        debug: isDev,
        testMode: isDev,
        gaOptions: {
            userId: user.email
        }
    });
    const {version} = await rampFetch({url: '/api/metadata/version'});
    const metadataFromStorage = loadFromLocalStorage(METADATA_STORAGE_KEY);
    const versionFromStorage = loadFromLocalStorage(VERSION_STORAGE_KEY);
    const notAllItemsExistInStorage = !metadataFromStorage ||
        METADATA_ITEMS.some((key) => typeof metadataFromStorage[key] === 'undefined' || metadataFromStorage[key].length === 0);
    let metadata = metadataFromStorage;
        try {
            if (notAllItemsExistInStorage || !versionFromStorage || version > versionFromStorage || version < 0) {
                metadata = await rampFetch({
                    url: '/api/metadata',
                    qs: {md: _.map(METADATA_ITEMS, item => item.name || item)},
                    failOnSecurity: true
                });
                if (!metadata.OppStage || metadata.OppStage.length === 0) {
                    metadata.OppStage = [
                        {id: 'stage_1', text: 'Closed Won', value: 100},
                        {id: 'stage_2', text: 'Waiting on IO', value: 80},
                        {id: 'stage_3', text: 'Order Likely', value: 60}
                    ];
                }
                saveToLocalStorage(METADATA_STORAGE_KEY, metadata);
                if (version > 0) {
                    saveToLocalStorage(VERSION_STORAGE_KEY, version);
                }
            }
            metadataProvider.init(metadata, METADATA_ITEMS);
            publisherTypesInit();
        } catch(err) {
            window.localStorage.removeItem('metadata');
            window.localStorage.removeItem('metadataVersion');
            return Promise.reject(err);
        }
};
